import { Route, Redirect } from 'react-router-dom'

import Signin from 'pages/Signin'
import Signup from 'pages/Signup'
import ResetPassword from 'pages/ResetPassword'

import Home from 'pages/Home'
import Course from 'pages/Course'
import CourseId from 'pages/Course/id'

import Account from 'pages/Account'
import MyCourses from 'pages/Course/myCourses'
import History from 'pages/Course/history'
import AccountProfile from 'pages/Account/account'

const privatePaths = [
  { path: '/course/:id/:lessonId/:time', component: CourseId },
  { path: '/course/:id', component: CourseId },
  { path: '/course', component: Course },
  { path: '/account/profile', component: AccountProfile },
  { path: '/mycourses', component: MyCourses },
  { path: '/history', component: History },
  { path: '/account', component: Account },
  { path: '/', component: Home }
]

const publicPaths = [
  { path: '/reset-password', component: ResetPassword },
  { path: '/signup', component: Signup },
  { path: '/', component: Signin }
]

export const PrivateRoute = ({ promptToInstall }) => (
  <>
    <Route path="*">
      <Redirect to="/" />
    </Route>
    {privatePaths.map(({ path, component: Component }, key) => (
      <Route
        exact
        key={key}
        path={path}
        render={(props) => <Component promptToInstall={promptToInstall} />}
      />
    ))}
  </>
)

export const PublicRoute = ({ login, loading }) => (
  <>
    {publicPaths.map(({ path, component: Component }, key) => (
      <Route
        exact
        key={key}
        path={path}
        render={(props) => <Component login={login} loading={loading} />}
      />
    ))}
  </>
)
