/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button as MuiButton,
  Link as MuiLink
} from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Carousel from 'react-material-ui-carousel'
import { Button } from 'components/Button'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { MainLayout } from 'layouts'
import { useAuth, useLesson } from 'hooks'

const APPLY_URL = process.env.REACT_APP_APPLY_URL

const Course = () => {
  const history = useHistory()
  const { t } = useTranslation('course')
  const [courseIndex, setCourseIndex] = useState(0)
  const { getCourses, loading } = useLesson()
  const [courses, setCourses] = useState([])
  const [expanded, setExpanded] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [didMount, setDidMount] = useState(false)

  const { user } = useAuth()

  useEffect(() => {
    if (user?.uid) {
      fetchData(user.uid)
    }
  }, [user])

  const fetchData = async (uid) => {
    const result = await getCourses(uid)

    setCourses(result)
  }

  const handleSubscription = async (courseId) => {
    setDialogOpen(false)
    window.open(APPLY_URL, '_blank', 'noopener,noreferrer')
    // await subscribeCourse(courseId)
    // fetchData(user.uid)
  }

  function truncateString(str, num) {
    if (str && str.length > num) {
      return str.slice(0, num) + '...'
    } else {
      return str
    }
  }

  useEffect(() => {
    setDidMount(true)
    return () => setDidMount(false)
  }, [])

  if (!didMount) {
    return null
  }

  return (
    <MainLayout title={t('title')} loading={loading} goBack>
      <Container maxWidth="sm">
        <Box mt={2} mb={2}>
          <Carousel
            autoPlay={false}
            animation="slide"
            onChange={(now, previous) => {
              setCourseIndex(now)
            }}
            index={courseIndex}
            navButtonsAlwaysVisible
            NextIcon={<ChevronRightIcon color="primary" fontSize="50px" />}
            PrevIcon={<ChevronLeftIcon color="primary" fontSize="50px" />}
            navButtonsProps={{
              // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
              style: {
                backgroundColor: 'transparent'
              }
            }}
          >
            {courses?.map((course) => (
              <Grid
                key={course.id}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Card
                  sx={{ maxWidth: 250 }}
                  style={{
                    backgroundColor: 'pink',
                    borderRadius: 30
                  }}
                >
                  <CardMedia
                    component="img"
                    height="250"
                    width="250"
                    image={course?.thumbnail}
                    alt={course?.title}
                    loading="lazy"
                  />
                </Card>
              </Grid>
            ))}
          </Carousel>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Card
              variant="outline"
              style={{ textAlign: 'center', justifyContent: 'center' }}
            >
              <CardHeader
                title={
                  <Typography variant="title" component="h1" color="primary">
                    {courses[courseIndex]?.header}
                  </Typography>
                }
              />

              <CardContent>
                <Typography
                  variant="body1"
                  component="p"
                  color="#865D3B"
                  sx={{ fontFamily: 'FC Lamoon Bold', textAlign: 'left' }}
                >
                  {expanded
                    ? courses[courseIndex]?.description
                    : truncateString(courses[courseIndex]?.description, 350)}

                  {courses[courseIndex]?.description.length >= 350 && (
                    <MuiLink
                      variant="button"
                      sx={{ fontFamily: 'FC Lamoon Bold', ml: 1 }}
                      onClick={() => setExpanded(!expanded)}
                    >
                      {expanded ? 'Show Less' : 'Show  More'}
                    </MuiLink>
                  )}
                </Typography>

                <Typography
                  variant="body"
                  component="h2"
                  color="primary"
                  mt={2}
                >
                  {courses[courseIndex]?.subheader}
                </Typography>
              </CardContent>

              <CardActions
                style={{
                  justifyContent: 'center'
                }}
              >
                {!courses[courseIndex]?.subscribed && (
                  <Button
                    onClick={() => {
                      setDialogOpen(true)
                    }}
                    variant="contained"
                    color="secondary"
                    style={{
                      marginRight: 10
                    }}
                  >
                    {t('course.buttonRegister')}
                  </Button>
                )}
                <Button
                  onClick={() => {
                    history.push(`/course/${courses[courseIndex]?.id}`)
                  }}
                  variant="contained"
                  color="secondary"
                >
                  {courses[courseIndex]?.subscribed
                    ? t('course.buttonLearn')
                    : t('course.buttonDetail')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Box>
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle>{t('subscription.title')}</DialogTitle>

          <DialogContent>
            <DialogContentText>{t('subscription.content')}</DialogContentText>
          </DialogContent>

          <DialogActions>
            <MuiButton onClick={() => setDialogOpen(false)} color="primary">
              {t('subscription.buttonCancel')}
            </MuiButton>

            <MuiButton
              onClick={() => {
                handleSubscription(courses[courseIndex].id)
              }}
              color="primary"
              autoFocus
            >
              {t('subscription.buttonOk')}
            </MuiButton>
          </DialogActions>
        </Dialog>
      </Container>
    </MainLayout>
  )
}

export default Course
