/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import {
  Snackbar,
  Alert,
  CssBaseline,
  Backdrop,
  CircularProgress
} from '@mui/material'
import { BrowserRouter as Router, Switch } from 'react-router-dom'

import { useAuth, useAddToHomescreenPrompt } from 'hooks'
import { PrivateRoute, PublicRoute } from 'routes'
import { useAppContext } from 'context'

export default function App() {
  const { loading, isLogin } = useAuth()
  const { message, setMessage } = useAppContext()
  const [promptToInstall] = useAddToHomescreenPrompt()

  const [snackBarOpen, setSnackBarOpen] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage(null)
    }, 4000)
    if (message) {
      setSnackBarOpen(true)
    }
    return () => clearTimeout(timer)
  }, [message])

  const handleCloseMessage = () => {
    setSnackBarOpen(false)
  }

  return loading ? (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Router>
      <Switch>
        {isLogin ? (
          <PrivateRoute promptToInstall={promptToInstall} />
        ) : (
          <PublicRoute />
        )}
      </Switch>
      <Snackbar
        open={snackBarOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={handleCloseMessage}
      >
        <Alert onClose={handleCloseMessage} severity={message?.type}>
          {message?.message}
        </Alert>
      </Snackbar>
      <CssBaseline />
    </Router>
  )
}
