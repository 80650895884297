import React, { useState, useContext, createContext } from 'react'

const AppContext = createContext({
  setUser: () => {},
  setMessage: () => {},
  setError: () => {}
})

const ContextProvider = ({ children }) => {
  const [user, setUser] = useState()
  const [error, setError] = useState()
  const [message, setMessage] = useState()
  const [modal, setModal] = useState()

  const [backdrop, setBackdrop] = useState(true)

  const { Provider } = AppContext

  const value = {
    user,
    setUser,
    error,
    setError,
    message,
    setMessage,
    backdrop,
    setBackdrop,
    modal,
    setModal
  }

  return <Provider value={value}>{children}</Provider>
}

const useAppContext = () => useContext(AppContext)

export { ContextProvider, AppContext, useAppContext }
