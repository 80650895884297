import React, { useState } from 'react'

import { Box, Grid, Typography, Link } from '@mui/material'

function truncateString(str, num) {
  if (str && str.length > num) {
    return str.slice(0, num) + '...'
  } else {
    return str
  }
}

export const Description = (props) => {
  const { title, description } = props
  const [expanded, setExpanded] = useState(false)

  return (
    <Box m={2}>
      <Grid item>
        <Typography variant="h6">{title}</Typography>
      </Grid>

      <Grid item>
        <Typography sx={{ fontFamily: 'FC Lamoon Bold' }}>
          {expanded ? description : truncateString(description, 350)}
          {description?.length >= 350 && (
            <Link
              component="button"
              sx={{ fontFamily: 'FC Lamoon Bold', ml: 1 }}
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? 'Show Less' : 'Show  More'}
            </Link>
          )}
        </Typography>
      </Grid>
    </Box>
  )
}

export default Description
