import { useState, useEffect } from 'react'
import { osName, isMobile } from 'react-device-detect'
import { useAppContext } from 'context'
import { MdIosShare } from 'react-icons/md'
import { BsThreeDotsVertical } from 'react-icons/bs'

export function useAddToHomescreenPrompt() {
  const { setModal } = useAppContext()

  const [prompt, setState] = useState(null)

  const promptToInstall = () => {
    if (prompt) {
      return prompt.prompt()
    } else if (isMobile && osName === 'iOS') {
      setModal({
        message: (
          <h2>
            Install this webapp on your iPhone : tap <MdIosShare /> in Safari
            and then Add to homescreen.
          </h2>
        )
      })
    } else if (isMobile && osName === 'Android') {
      setModal({
        message: (
          <h2>
            Install this webapp on your Android : tap <BsThreeDotsVertical /> in
            Chorme and then Add to homescreen.
          </h2>
        )
      })
    }
  }

  useEffect(() => {
    const ready = (e) => {
      e.preventDefault()
      setState(e)
    }

    window && window.addEventListener('beforeinstallprompt', ready)

    return () => {
      window.removeEventListener('beforeinstallprompt', ready)
    }
  }, [])

  return [prompt, promptToInstall]
}
