import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  size: {
    width: (props) => {
      switch (props.size) {
        case 'small':
          return theme.spacing(5)
        case 'large':
          return theme.spacing(9)

        default:
          return theme.spacing(5)
      }
    },
    height: (props) => {
      switch (props.size) {
        case 'small':
          return theme.spacing(5)
        case 'large':
          return theme.spacing(9)

        default:
          return theme.spacing(5)
      }
    }
  }
}))

export default useStyles
