/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

import { useAppContext } from 'context'
import { db, SUCCESS } from 'configs'

import {
  collection,
  getDocs,
  getDoc,
  doc,
  orderBy,
  query,
  writeBatch,
  addDoc,
  where,
  deleteDoc,
  setDoc,
  updateDoc
} from 'firebase/firestore'
import { useImage } from 'hooks'

export const useLesson = () => {
  const [loading, setLoading] = useState(false)
  const { user, setError, setMessage } = useAppContext()
  const { upload } = useImage()

  useEffect(() => {
    if (user?.uid) {
    }
  }, [])

  const getCourses = async () => {
    try {
      setLoading(true)

      const courseDocsSnapshot = await getDocs(
        query(collection(db, `course`), orderBy('order', 'asc'))
      )

      const courseDocs = courseDocsSnapshot.docs.map((doc) => ({
        id: doc?.id,
        ...doc?.data()
      }))

      const courseDocsSubsctibedByUser =
        (await Promise.all(
          courseDocs.map(async (course) => {
            const courseRef = doc(db, `course`, course.id)
            const profileRef = collection(courseRef, `profile`)
            const profileSnapshot = await getDocs(query(profileRef))

            const subscribed = profileSnapshot.docs.some(
              (profile) => profile?.id === user.uid
            )

            return { ...course, subscribed }
          })
        )) || []

      setLoading(false)

      return courseDocsSubsctibedByUser
    } catch (error) {
      setError(error)
    }
  }

  const getCourse = async (id, notRefresh) => {
    try {
      !notRefresh && setLoading(true)
      const courseRef = doc(db, `course`, id)
      const lessonRef = collection(courseRef, `lessons`)
      const profileRef = collection(courseRef, `profile`)
      const documentRef = collection(courseRef, `documents`)
      const homeworkRef = collection(db, `feeds`)
      const albumRef = collection(courseRef, `albums`)

      const courseSnapshot = await getDoc(courseRef)
      const course = { id: courseSnapshot.id, ...courseSnapshot.data() }

      const lessonSnapshot = await getDocs(
        query(lessonRef, orderBy('order', 'asc'))
      )

      const lessons = lessonSnapshot.docs.map((doc) => ({
        id: doc?.id,
        ...doc?.data()
      }))

      const profileSnapshot = await getDocs(query(profileRef))

      const subscribed = profileSnapshot.docs.some(
        (profile) => profile?.id === user.uid
      )

      const documentSnapshot = await getDocs(query(documentRef))

      const documents = documentSnapshot.docs.map((doc) => ({
        id: doc?.id,
        ...doc?.data()
      }))

      const albumSnapshot = await getDocs(query(albumRef))

      const albums = albumSnapshot.docs.map((doc) => ({
        id: doc?.id,
        ...doc?.data()
      }))

      const homeworkSnapshot = await getDocs(
        query(
          homeworkRef,
          where('userId', '==', user.uid),
          where('course.id', '==', id)
        )
      )

      const homeworks = homeworkSnapshot.docs.map((doc) => ({
        id: doc?.id,
        ...doc?.data()
      }))

      const homeworksWithProfile =
        (await Promise.all(
          homeworks.map(async (feed) => {
            const profileRef = doc(db, `profile`, feed.userId)
            const profileSnapshot = await getDoc(query(profileRef))

            let comments =
              (await Promise.all(
                feed?.comments?.map(async (comment) => {
                  const commentProfileRef = doc(db, `profile`, comment.userId)

                  const commentProfileSnapshot = await getDoc(
                    query(commentProfileRef)
                  )

                  return {
                    ...comment,
                    user: commentProfileSnapshot.data()
                  }
                })
              )) || []

            return {
              ...feed,
              comments,
              user: profileSnapshot.data()
            }
          })
        )) || []

      setLoading(false)

      return {
        ...course,
        lessons,
        documents,
        subscribed,
        albums,
        homeworks: homeworksWithProfile
      }
    } catch (error) {
      setError(error)
    }
  }

  const subscribeCourse = async (courseId) => {
    setLoading(true)

    const { uid } = user
    const profileRef = doc(db, `profile/${uid}/course/${courseId}`)
    const courseRef = doc(db, `course/${courseId}/profile/${uid}`)

    const batch = writeBatch(db)

    batch.set(profileRef, {})
    batch.set(courseRef, {})

    await batch.commit()
    setLoading(false)
  }

  const unsubscribeCourse = async (courseId) => {
    setLoading(true)

    const { uid } = user
    const profileRef = doc(db, `profile/${uid}/course/${courseId}`)
    const courseRef = doc(db, `course/${courseId}/profile/${uid}`)

    const batch = writeBatch(db)

    batch.delete(profileRef)
    batch.delete(courseRef)

    await batch.commit()
    setLoading(false)
  }

  const addHomeWork = async (course, values) => {
    upload(
      values.fileUpload,
      `${course.id}_${values.fileUpload.name}.png`,
      'homework',
      async (photoURL) => {
        let data = {
          title: values.title,
          detail: values.detail,
          course: {
            id: course.id,
            title: course.header
          },
          type: 'homework',
          typeTh: 'การบ้าน',
          createdAt: new Date(),
          userId: user?.uid,
          photoURL: photoURL
        }

        try {
          setLoading(true)

          await addDoc(collection(db, 'feeds'), {
            ...data,
            likes: [],
            comments: []
          })

          setLoading(false)
          setMessage({ type: SUCCESS, message: 'Send HomeWork successful' })
          return 'success'
        } catch (error) {
          setError(error)
          return 'fail'
        }
      }
    )
  }

  const updateHomeWork = async (id, data) => {
    if (typeof data.fileUpload === 'string') {
      try {
        setLoading(true)
        let newData = {
          title: data.title,
          detail: data.detail,
          photoURL: data.fileUpload
        }

        await setDoc(doc(db, 'feeds', id), newData, {
          merge: true
        })
        setLoading(false)
        setMessage({ type: SUCCESS, message: 'Update Homework successful' })

        return 'success'
      } catch (error) {
        console.error(error)
        setError(error)
      }
    } else {
      upload(
        data.fileUpload,
        `${id}_${data.fileUpload.name}.png`,
        'homework',
        async (newPhotoURL) => {
          let newData = {
            title: data.title,
            detail: data.detail,
            photoURL: newPhotoURL
          }
          try {
            setLoading(true)

            await setDoc(doc(db, 'feeds', id), newData, {
              merge: true
            })
            setLoading(false)
            setMessage({ type: SUCCESS, message: 'Update Homework successful' })

            return 'success'
          } catch (error) {
            setError(error)
            console.error(error)
          }
        }
      )
    }
  }

  const deleteHomeWork = async (id) => {
    try {
      setLoading(true)
      const contentRef = doc(db, `feeds`, id)

      await deleteDoc(contentRef)
      setLoading(false)
      setMessage({ type: SUCCESS, message: 'Delete Homework successful' })

      return 'success'
    } catch (error) {
      setError(error)
    }
  }

  const updateWatchHistory = async (update) => {
    try {
      const { uid } = user
      const { course, lesson, duration } = update
      const profileRef = doc(db, `profile/${uid}/course/${course.id}/`)
      return await updateDoc(profileRef, {
        playedAt: new Date(),
        course,
        lesson,
        duration
      })
    } catch (error) {
      setError(error)
    }
  }

  const getAllWatchHistory = async () => {
    try {
      const { uid } = user
      const profileRef = doc(db, `profile`, uid)
      const courseRef = collection(profileRef, `course`)
      const courseSnapshot = await getDocs(query(courseRef))
      const lastLesson = courseSnapshot.docs.map((course) => course.data())
      return lastLesson.filter((lesson) => lesson.playedAt)
    } catch (error) {
      setError(error)
    }
  }

  return {
    getCourses,
    getCourse,
    loading,
    subscribeCourse,
    unsubscribeCourse,
    addHomeWork,
    deleteHomeWork,
    updateHomeWork,
    updateWatchHistory,
    getAllWatchHistory
  }
}
