/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import {
  Container,
  Box,
  FormControl,
  TextField,
  CardContent,
  Grid,
  Stack
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAuth } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import UploadAvatarImage from 'components/UploadAvatarImage'

const validationSchema = (t) => {
  /* istanbul ignore next */
  return yup.object().shape({
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    phoneNumber: yup.string().required()
  })
}

export const Profile = () => {
  const { t } = useTranslation('account')
  const { user, loading, updateProfile } = useAuth()
  const history = useHistory()

  const onSubmit = async (values) => {
    await updateProfile(values)
    history.push('/account')
  }

  const { handleSubmit, setValue, control } = useForm({
    resolver: yupResolver(validationSchema(t)),
    defaultValues: {
      firstname: '',
      lastname: '',
      phoneNumber: ''
    }
  })

  useEffect(() => {
    if (user) {
      setValue('firstname', user?.profile?.firstname || '')
      setValue('lastname', user?.profile?.lastname || '')
      setValue('phoneNumber', user?.profile?.phoneNumber || '')
    }
  }, [user])

  return (
    <Box mt={2}>
      <Container component="main" maxWidth="sm">
        <Box>
          <CardContent>
            <Grid item>
              <Box mb={3}>
                <UploadAvatarImage
                  filename={`profile-${user?.uid}.png`}
                  imageUrl={user?.profile?.photoURL || null}
                  callback={(photoURL) => updateProfile({ photoURL })}
                />
              </Box>
            </Grid>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3}>
                <FormControl fullWidth>
                  <Controller
                    name="firstname"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error }
                    }) => (
                      <TextField
                        label={t(`profile.firstname`)}
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Controller
                    name="lastname"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error }
                    }) => (
                      <TextField
                        label={t(`profile.lastname`)}
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error }
                    }) => (
                      <TextField
                        type="number"
                        label={t(`profile.tel`)}
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </FormControl>

                <Box display="flex" justifyContent="center">
                  <LoadingButton
                    id="btnUpdateProfile"
                    color="primary"
                    type="submit"
                    variant="contained"
                    style={{ color: 'white' }}
                    loading={loading}
                    loadingIndicator={t('profile.buttonLoading')}
                    sx={{
                      borderRadius: 20,
                      width: 150
                    }}
                  >
                    {t(`profile.buttonSave`)}
                  </LoadingButton>
                </Box>
              </Stack>
            </form>
          </CardContent>
        </Box>
      </Container>
    </Box>
  )
}

export default Profile
