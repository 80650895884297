/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import BottomNavigation from '@mui/material/BottomNavigation'
import MuiBottomNavigationAction from '@mui/material/BottomNavigationAction'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import HomeIcon from '@mui/icons-material/Home'
import ProfileIcon from '@mui/icons-material/Person'

import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { styled } from '@mui/material/styles'

const BottomNavigationAction = styled(MuiBottomNavigationAction)(`
  color: white;
  &.Mui-selected {
    color: #EB557A;
  }
`)

export default function FixedBottomNavigation() {
  const { t } = useTranslation('common')
  const location = useLocation()
  const history = useHistory()
  const { id } = useParams()
  const [value, setValue] = useState()
  const ref = useRef(null)

  useEffect(() => {
    if (value) {
      ref.current.ownerDocument.body.scrollTop = 0
      history.push(value)
    }
  }, [value])

  useEffect(() => {
    if (!id) {
      setValue(location.pathname)
    }
  }, [location.pathname])

  const menuList = [
    {
      key: 'menuHome',
      label: t('bottomNavigation.menu.home'),
      icon: <HomeIcon />,
      link: '/'
    },
    {
      key: 'menuCourse',
      label: t('bottomNavigation.menu.course'),
      icon: <MenuBookIcon />,
      link: '/myCourses'
    },
    {
      key: 'menuAccount',
      label: t('bottomNavigation.menu.account'),
      icon: <ProfileIcon />,
      link: '/account'
    }
  ]

  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0
        }}
        elevation={3}
      >
        <BottomNavigation
          style={{
            backgroundColor: '#A1D6D5',
            zIndex: 10
          }}
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue)
          }}
        >
          {menuList.map((menu) => (
            <BottomNavigationAction
              disabled={!menu.link}
              key={menu.key}
              label={menu.label}
              icon={menu.icon}
              value={menu.link}
            />
          ))}
        </BottomNavigation>
      </Paper>
    </Box>
  )
}
