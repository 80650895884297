/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  Typography,
  List,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemAvatar,
  ListItemButton
} from '@mui/material'
import Avatar from 'components/Avatar'
import { useAuth } from 'hooks'
import { useAppContext } from 'context'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import ProfileIcon from '@mui/icons-material/Person'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import LanguageIcon from '@mui/icons-material/Language'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

import { MainLayout } from 'layouts'

const Homepage = () => {
  const history = useHistory()
  const { user, loading, logout } = useAuth()
  const { t, i18n } = useTranslation('account')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogLanguage, setDialogLanguage] = useState(false)
  const { setBackdrop } = useAppContext()

  const menuList = [
    {
      key: 'menuAccount',
      label: t('menu.account'),
      icon: <ProfileIcon />,
      link: '/account/profile'
    },
    {
      key: 'menuDisclaimer',
      label: t('menu.disclaimer'),
      icon: <LibraryBooksIcon />
      // link: '/account'
    },
    {
      key: 'menuFaq',
      label: t('menu.faq'),
      icon: <ContactSupportIcon />
      // link: '/account'
    }
  ]

  const handleLogout = () => {
    setDialogOpen(false)
    setBackdrop(true)
    logout()
    history.push('/')
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
    setDialogLanguage(false)
  }
  return (
    <MainLayout title={t('title')}>
      {!loading ? (
        <Box mt={2}>
          <Container component="main" maxWidth="sm">
            <Box minWidth="250px">
              <Box p={2}>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Avatar
                      src={user?.profile?.photoURL || undefined}
                      size="large"
                    />
                  </Grid>

                  <Grid item>
                    <Box mt={2}>
                      <Typography>{`Email : ${user?.email}`}</Typography>
                    </Box>
                    <Box mt={2}>
                      <Typography>{`Name : ${user?.profile?.firstname} ${user?.profile?.lastname}`}</Typography>
                    </Box>
                    <Box mt={2}>
                      <Typography>{`Telephone : ${user?.profile?.phoneNumber}`}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <List sx={{ width: '100%' }}>
                {menuList.map((menu) => (
                  <ListItemButton
                    id={menu.key}
                    key={menu.label}
                    onClick={() => history.push(menu.link)}
                    sx={{ width: '100%' }}
                  >
                    <ListItemIcon>{menu.icon}</ListItemIcon>

                    <ListItemText primary={menu.label} />
                    <KeyboardArrowRightIcon />
                  </ListItemButton>
                ))}
                <ListItemButton
                  id="menu-lang"
                  onClick={() => setDialogLanguage(true)}
                  sx={{ width: '100%' }}
                >
                  <ListItemIcon sx={{ alignItems: 'flex-end' }}>
                    <LanguageIcon />
                  </ListItemIcon>

                  <ListItemText primary={t('menu.language')} />
                </ListItemButton>

                <ListItemButton
                  id="menu-logout"
                  onClick={() => setDialogOpen(true)}
                  sx={{ width: '100%' }}
                >
                  <ListItemIcon sx={{ alignItems: 'flex-end' }}>
                    <PowerSettingsNewIcon />
                  </ListItemIcon>

                  <ListItemText primary={t('menu.logout')} />
                </ListItemButton>
              </List>
            </Box>
          </Container>
          <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <DialogTitle>{t('logout.title')}</DialogTitle>

            <DialogContent>
              <DialogContentText>{t('logout.content')}</DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={() => setDialogOpen(false)} color="primary">
                {t('logout.buttonCancel')}
              </Button>

              <Button onClick={handleLogout} color="primary" autoFocus>
                {t('logout.buttonOk')}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            sx={{ '& .MuiDialog-paper': { width: '50%', maxHeight: 435 } }}
            onClose={() => setDialogLanguage(false)}
            open={dialogLanguage}
          >
            <DialogTitle>{t('language.title')}</DialogTitle>
            <List sx={{ pt: 0 }}>
              {[
                { label: 'thai', code: 'th' },
                { label: 'english', code: 'en' }
              ].map((lang) => (
                <ListItemButton
                  sx={{ width: '100%' }}
                  key={lang.code}
                  onClick={() => changeLanguage(lang.code)}
                >
                  <ListItemAvatar>
                    <Avatar>{lang.code.toUpperCase()}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={t(`language.${lang.label}`)} />
                </ListItemButton>
              ))}
            </List>
          </Dialog>
        </Box>
      ) : (
        <LinearProgress />
      )}
    </MainLayout>
  )
}

export default Homepage
