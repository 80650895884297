/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import {
  AppBar,
  IconButton,
  Typography,
  Toolbar,
  Box,
  Backdrop,
  Slide,
  CircularProgress,
  LinearProgress,
  Fade,
  Modal
} from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import BottomNavigation from '../components/BottomNavigation'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'hooks'
import { useAppContext } from 'context'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'

function truncateString(str, num) {
  if (str && str.length > num) {
    return str.slice(0, num) + '...'
  } else {
    return str
  }
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  bgcolor: '#FFF',
  p: 3,
  borderRadius: 5
}

function HideOnScroll(props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined
  })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

export const MainLayout = (props) => {
  const { title, children, goBack, promptToInstall } = props
  const { backdrop, setBackdrop, setModal, modal } = useAppContext()
  const { user } = useAuth()
  let history = useHistory()
  const [didMount, setDidMount] = useState(false)

  useEffect(() => {
    if (user !== undefined) {
      handleClose()
    } else if (user === null) {
      history.push('/signin')
    }
  }, [user])

  const handleClose = () => {
    setBackdrop(false)
  }

  useEffect(() => {
    setDidMount(true)
    return () => setDidMount(false)
  }, [])

  if (!didMount) {
    return null
  }

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal open={Boolean(modal)} onClose={() => setModal(null)}>
        <Box sx={style}>{modal?.message}</Box>
      </Modal>
      <HideOnScroll {...props}>
        <AppBar position="sticky" elevation={0}>
          <Toolbar>
            {goBack && (
              <IconButton
                id="btnGoback"
                color="white"
                aria-label="Goback"
                sx={{ mr: 2 }}
                onClick={() => history.goBack()}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
            )}
            <Typography
              sx={{ ml: 2, mr: 2, flexGrow: 2 }}
              id="titlePage"
              variant="h6"
              color="#FFF"
            >
              {truncateString(title, 35)}
            </Typography>
            {promptToInstall && (
              <IconButton color="white" onClick={promptToInstall}>
                <ArrowCircleDownIcon />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      {props.loading && <LinearProgress />}
      <Fade in={!props.loading}>
        <Box my={0}>{children}</Box>
      </Fade>
      {!goBack && <BottomNavigation />}
    </div>
  )
}

export default MainLayout
