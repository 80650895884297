import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import th from './th'
import en from './en'

i18next.use(initReactI18next).init({
  fallbackLng: 'th',
  resources: { th, en },
  lng: 'th',
  debug: false,
  interpolation: {
    escapeValue: false
  }
})

export default i18next
