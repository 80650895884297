import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */,
  },
  player: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
}))

export default useStyles
