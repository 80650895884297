import account from './account.json'
import common from './common.json'
import course from './course.json'
import home from './home.json'
import resetpassword from './resetpassword.json'
import signin from './signin.json'
import signup from './signup.json'

const th = { home, account, common, course, resetpassword, signin, signup }
export default th
