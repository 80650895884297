/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import {
  Container,
  Box,
  FormControl,
  TextField,
  Stack,
  Fab,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Zoom,
  Slide
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useLesson, useFeeds } from 'hooks'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import UploadBox from 'components/UploadBox'
import FeedCard from 'components/FeedCard'
import AddIcon from '@mui/icons-material/Add'

const validationSchema = (t) => {
  /* istanbul ignore next */
  return yup.object().shape({
    title: yup.string().required(),
    detail: yup.string().required()
    // fileUpload: yup.string().required()
  })
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const HomeworkForm = ({ course, setTab, fetchData, isLaptop }) => {
  const { t } = useTranslation('course')
  const { addHomeWork, deleteHomeWork, updateHomeWork } = useLesson()
  const [globalExpanded, setGlobalExpanded] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)

  const { clickLikeFeed, commentFeed } = useFeeds()

  const { handleSubmit, setValue, control, reset } = useForm({
    resolver: yupResolver(validationSchema(t))
  })
  const onSubmit = async (values) => {
    if (values.id) {
      await updateHomeWork(values.id, values)
    } else {
      await addHomeWork(course, values)
    }
    setDialogOpen(false)
    setValue('title', '')
    setValue('detail', '')
    setValue('fileUpload', '')
    reset()
    setTab()

    setTimeout(() => {
      fetchData()
    }, 1000)
  }

  const handleDeleteHomework = async (id) => {
    await deleteHomeWork(id)
    setTimeout(() => {
      fetchData()
    }, 1000)
  }

  const handleEditHomework = async (data) => {
    setValue('title', data.title)
    setValue('detail', data.detail)
    setValue('fileUpload', data.photoURL)
    setValue('id', data.id)

    setDialogOpen(true)
  }

  const handleComment = async (feedId, comments, newComment) => {
    let result = await commentFeed(feedId, comments, newComment)
    if (result) {
      fetchData()
    }
  }

  const handleLike = async (feedId, likes) => {
    let result = await clickLikeFeed(feedId, likes)
    if (result) {
      fetchData()
    }
  }
  return (
    <>
      <Zoom
        in={!dialogOpen}
        timeout={100}
        style={{
          transitionDelay: `${100}ms`,
          float: 'right'
        }}
        unmountOnExit
      >
        <Fab
          color="primary"
          aria-label="add"
          variant="extended"
          sx={{
            position: isLaptop ? 'relative' : 'fixed',
            bottom: 16,
            right: 16,
            color: 'white'
          }}
          onClick={() => setDialogOpen(true)}
        >
           
          <AddIcon color="white" sx={{ mr: 1 }} />
          <Typography color="#FFF">ส่งการบ้าน</Typography>
        </Fab>
      </Zoom>
      <Container component="main" maxWidth="sm">
        <Box mb={'4rem'}>
          {course?.homeworks
            ?.sort((a, b) => b.createdAt - a.createdAt)
            .map((feed, index) => (
              <FeedCard
                key={`feeds-key-${index}`}
                data={feed}
                index={index}
                expanded={globalExpanded === index}
                setGlobalExpanded={setGlobalExpanded}
                deleteBtn
                deleteHomeWork={() => handleDeleteHomework(feed.id)}
                handleEdit={() => handleEditHomework(feed)}
                handleComment={(newComment) =>
                  handleComment(feed.id, feed.comments, newComment)
                }
                clickLikeFeed={() => handleLike(feed.id, feed.likes)}
              />
            ))}
        </Box>

        <Dialog
          sx={{ '& .MuiDialog-paper': { width: '90%' } }}
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          TransitionComponent={Transition}
        >
          <DialogTitle>{t('homework.header')}</DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <Stack spacing={3}>
                <FormControl fullWidth>
                  <Controller
                    name="title"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error }
                    }) => (
                      <TextField
                        label={t(`homework.title`)}
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Controller
                    name="detail"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error }
                    }) => (
                      <TextField
                        label={t(`homework.detail`)}
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        multiline
                        rows={4}
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Controller
                    name="fileUpload"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error }
                    }) => (
                      <>
                        <UploadBox
                          file={value}
                          callback={(file) => {
                            setValue('fileUpload', file)
                          }}
                        />
                      </>
                    )}
                  />
                </FormControl>
              </Stack>
            </DialogContent>

            <DialogActions>
              <Button onClick={() => setDialogOpen(false)} color="primary">
                {t('homework.buttonCancel')}
              </Button>

              <Button color="primary" autoFocus type="submit">
                {t(`homework.buttonOk`)}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Container>
    </>
  )
}

export default HomeworkForm
