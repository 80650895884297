/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import { MainLayout } from 'layouts'
import { useTranslation } from 'react-i18next'
import { Tab, Box } from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Profile from './profile'
import ChangePassword from './changePassword'

export const Account = () => {
  const { t } = useTranslation('account')
  const [tab, setTab] = useState('1')

  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }

  return (
    <MainLayout title={t(`profile.title`)} goBack>
      <TabContext value={tab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider'
          }}
        >
          <TabList onChange={handleTabChange} variant="fullWidth">
            <Tab label={t(`profile.tabProfile`)} value="1" />
            <Tab label={t(`profile.tabPassword`)} value="2" />
          </TabList>
        </Box>
        <Box>
          <TabPanel
            value="1"
            sx={{
              margin: 0,
              padding: 0
            }}
          >
            <Profile />
          </TabPanel>
          <TabPanel value="2">
            <ChangePassword />
          </TabPanel>
        </Box>
      </TabContext>
    </MainLayout>
  )
}

export default Account
