import React from 'react'
import { Box } from '@mui/material'
import ReactPlayer from 'react-player/lazy'

import useStyles from './styles'

export const VideoPlayer = (props) => {
  const { url, onEnded, player, onProgress, onReady } = props
  const classes = useStyles()

  return (
    <Box className={classes.wrapper}>
      <ReactPlayer
        ref={player}
        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
        onContextMenu={(e) => e.preventDefault()}
        url={url}
        controls
        className={classes.player}
        width="100%"
        height="100%"
        onEnded={onEnded}
        progressInterval={15000}
        onProgress={onProgress}
        onReady={onReady}
      />
    </Box>
  )
}

export default VideoPlayer
