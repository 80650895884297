import { Avatar as MAvatar } from '@mui/material'
import useStyles from './styles'

export const Avatar = (props) => {
  const { src, size } = props
  const classes = useStyles({ size })

  return <MAvatar src={src} className={classes.size} size="large" />
}

export default Avatar
