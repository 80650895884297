import { useState, useEffect } from 'react'
import StyledTextField from 'components/StyledTextField'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import LoadingButton from '@mui/lab/LoadingButton'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'hooks'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Layout } from 'layouts'
import PersonIcon from '@mui/icons-material/Person'
import LockIcon from '@mui/icons-material/Lock'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import { useHistory } from 'react-router-dom'

const validationSchema = (t) =>
  yup.object().shape({
    email: yup
      .string()
      .email(t('validation.email.valid'))
      .required(t('validation.email.required')),
    password: yup.string().required(t('validation.password.required'))
  })

export function SignIn() {
  const { t } = useTranslation('signin')
  const { login, loading } = useAuth()
  const [showPassword, setShow] = useState(false)
  const history = useHistory()

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(validationSchema(t))
  })

  const errors = formState.errors

  const handleClickShowPassword = () => {
    setShow(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const onSubmit = async (values) => {
    const { email, password } = values

    await login(email, password)
  }

  const [didMount, setDidMount] = useState(false)

  useEffect(() => {
    setDidMount(true)
    return () => setDidMount(false)
  }, [])

  if (!didMount) {
    return null
  }
  return (
    <Layout
      style={{
        backgroundColor: '#FFA3AC'
      }}
    >
      <Container
        component="main"
        maxWidth="sm"
        style={{
          minHeight: '120vh',
          backgroundColor: '#FFA3AC'
        }}
      >
        <Box
          sx={{
            pt: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography component="h1" variant="title" color="#fff">
            {t('title')}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 1, px: 3 }}
          >
            <FormControl margin="normal" fullWidth>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PersonIcon
                  sx={{ mr: 1, my: 0.5, color: '#FFF' }}
                  fontSize="large"
                />
                <StyledTextField
                  InputLabelProps={{
                    style: {
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: '100%',
                      color: 'white'
                    }
                  }}
                  fullWidth
                  id="inputEmail"
                  label={t('email')}
                  {...register('email')}
                  error={!!errors?.email?.message}
                  helperText={errors?.email?.message}
                />
              </Box>
            </FormControl>

            <FormControl margin="normal" fullWidth>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LockIcon
                  sx={{ mr: 1, my: 0.5, color: '#FFF' }}
                  fontSize="large"
                />
                <StyledTextField
                  InputLabelProps={{
                    style: {
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: '100%',
                      color: 'white'
                    }
                  }}
                  InputProps={{
                    autoComplete: 'password',
                    form: {
                      autoComplete: 'off'
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          sx={{ mr: 0.5, color: '#FFF' }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  fullWidth
                  id="inputPassword"
                  type={showPassword ? 'text' : 'password'}
                  label={t('password')}
                  {...register('password')}
                  error={!!errors?.password?.message}
                  helperText={errors?.password?.message}
                />
              </Box>
            </FormControl>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                variant="link"
                style={{
                  borderRadius: 20,
                  color: '#FFF',
                  textDecoration: 'underline'
                }}
                onClick={() => history.push('/reset-password')}
              >
                <Typography component="h1" color="white">
                  {t('forgotPassword')}
                </Typography>
              </Button>
            </Grid>
            <Box px={1}>
              <LoadingButton
                color="white"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3 }}
                style={{ color: '#FFA3AC', borderRadius: 20 }}
                loading={loading}
                loadingIndicator={t('buttonLoading')}
              >
                <Typography component="h1">{t('btnLogin')}</Typography>
              </LoadingButton>

              <Button
                color="white"
                fullWidth
                variant="outlined"
                sx={{ mt: 2, mb: 2 }}
                style={{ borderRadius: 20 }}
                onClick={() => history.push('/signup')}
              >
                <Typography component="h1" color="white">
                  {t('btnSignUp')}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}

export default SignIn
