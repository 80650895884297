/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'

import { useAppContext } from 'context'
import { db } from 'configs'

import {
  collection,
  getDocs,
  orderBy,
  query,
  doc,
  getDoc,
  setDoc
} from 'firebase/firestore'
import { search } from 'ss-search'

export const useFeeds = () => {
  const [loading, setLoading] = useState(false)
  const [posting, setPosting] = useState(false)

  const { setError, user } = useAppContext()

  const getFeeds = async (id, notRefresh) => {
    try {
      !notRefresh && setLoading(true)

      const feedsDocsSnapshot = await getDocs(
        query(collection(db, `feeds`), orderBy('createdAt', 'desc'))
      )

      const feedsDocs = feedsDocsSnapshot.docs.map((doc) => ({
        id: doc?.id,
        ...doc?.data()
      }))

      const feedsDocswithUserProfile =
        (await Promise.all(
          feedsDocs.map(async (feed) => {
            const profileRef = doc(db, `profile`, feed.userId)
            const profileSnapshot = await getDoc(query(profileRef))

            let comments =
              (await Promise.all(
                feed?.comments?.map(async (comment) => {
                  const commentProfileRef = doc(db, `profile`, comment.userId)

                  const commentProfileSnapshot = await getDoc(
                    query(commentProfileRef)
                  )

                  return {
                    ...comment,
                    user: commentProfileSnapshot.data()
                  }
                })
              )) || []

            return {
              ...feed,
              comments,
              user: profileSnapshot.data()
            }
          })
        )) || []

      setLoading(false)

      return feedsDocswithUserProfile
    } catch (error) {
      setError(error)
    }
  }

  const getBanners = async () => {
    try {
      const bannerDocsSnapshot = await getDocs(
        query(collection(db, `banner`), orderBy('order', 'asc'))
      )

      const bannerDocs = bannerDocsSnapshot.docs.map((doc) => ({
        id: doc?.id,
        ...doc?.data()
      }))

      setLoading(false)

      return bannerDocs
    } catch (error) {
      setError(error)
    }
  }

  const clickLikeFeed = async (id, likesArray) => {
    if (likesArray?.indexOf(user.uid) === -1) {
      likesArray.push(user.uid)
    } else {
      likesArray = likesArray.filter((id) => id !== user.uid)
    }

    try {
      await setDoc(
        doc(db, 'feeds', id),
        {
          likes: likesArray
        },
        {
          merge: true
        }
      )

      return 'liked'
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  const commentFeed = async (id, comments, text) => {
    setPosting(true)
    const newComment = {
      text: text,
      userId: user.uid,
      createdAt: new Date()
    }

    comments.push(newComment)

    try {
      await setDoc(
        doc(db, 'feeds', id),
        {
          comments: comments
        },
        {
          merge: true
        }
      )
      setPosting(false)
      return 'liked'
    } catch (error) {
      console.log(error)
      setError(error)
      console.error(error)
      setPosting(false)
    }
  }

  const searchFeed = async (searchText) => {
    try {
      const feeds = await getFeeds(null, true)

      const searchKeys = [
        'title',
        'detail',
        'user.firstname',
        'user.lastname',
        'type',
        'typeTh'
      ]

      const results = search(feeds, searchKeys, searchText)

      return results
    } catch (error) {
      console.log(error)
    }
  }

  return {
    getFeeds,
    loading,
    posting,
    clickLikeFeed,
    commentFeed,
    searchFeed,
    getBanners
  }
}
