import React, { useRef, useState } from 'react'

import { Box, IconButton, Button } from '@mui/material'
import Compressor from 'compressorjs'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import UploadIcon from '@mui/icons-material/Upload'

const PREVIEW_SIZE = 500
const UPLOAD_SIZE = 500

export const UploadBox = (props) => {
  const { file, callback } = props
  const inputFile = useRef(null)
  const [preview, setPreview] = useState(null)
  const [uploadFile, setUploadFile] = useState(null)

  const handleChange = (e) => {
    const { target } = e

    const files = e.target.files

    if (target.validity.valid) {
      // Resize Preview Image
      new Compressor(files[0], {
        quality: 1,
        width: PREVIEW_SIZE,
        height: PREVIEW_SIZE,
        success(result) {
          setPreview(result)
        }
      })

      // Resize Upload Image
      new Compressor(files[0], {
        quality: 1,
        width: UPLOAD_SIZE,
        height: UPLOAD_SIZE,
        success(result) {
          setUploadFile(result)
        }
      })
    }
  }

  const handleUpload = () => {
    callback(uploadFile)
    setPreview(null)
    setUploadFile(null)
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <input
        id="button-file"
        ref={inputFile}
        type="file"
        accept="image/*"
        onChange={handleChange}
        style={{ display: 'none' }}
      />

      <Box
        container
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        {preview ? (
          <Box
            sx={{ maxWidth: 300 }}
            component="img"
            alt="preview"
            src={URL.createObjectURL(preview)}
          />
        ) : (
          <>
            {file && (
              <Box
                sx={{ maxWidth: 300 }}
                component="img"
                alt="preview"
                src={
                  typeof file === 'string' ? file : URL.createObjectURL(file)
                }
              />
            )}
            <Button
              color="primary"
              variant="contained"
              style={{ color: 'white', marginTop: 15 }}
              endIcon={<UploadIcon />}
              onClick={() => {
                if (inputFile.current !== null) {
                  inputFile?.current.click()
                }
              }}
            >
              Click to Upload
            </Button>
          </>
        )}
      </Box>
      {preview && (
        <Box>
          <IconButton
            size="small"
            onClick={() => {
              setPreview(null)
              setUploadFile(null)
              callback('')
            }}
            color="secondary"
          >
            <ClearIcon />
          </IconButton>

          <IconButton size="small" onClick={handleUpload} color="primary">
            <CheckIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

export default UploadBox
