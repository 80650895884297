/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  LinearProgress,
  Typography,
  Stack,
  ButtonBase
} from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { MainLayout } from 'layouts'
import { useAuth, useLesson } from 'hooks'

const MyCourses = () => {
  const history = useHistory()
  const { t } = useTranslation('account')
  const { loading, getCourses } = useLesson()
  const [courses, setCourses] = useState([])
  const { user } = useAuth()

  useEffect(() => {
    if (user?.uid) {
      fetchData(user.uid)
    }
  }, [user])

  const fetchData = async (uid) => {
    const result = await getCourses(uid)

    setCourses(result)
  }

  function MyCourse(course, index) {
    return (
      <Grid item my={2} key={`course-key-${index}`}>
        <Card
          sx={{
            display: 'flex',
            borderRadius: 5
          }}
          onClick={() => {
            history.push(`/course/${course?.id}`)
          }}
        >
          <ButtonBase>
            <CardMedia
              component="img"
              sx={{ width: 110, height: 110 }}
              image={course.thumbnail}
              alt={course.header}
            />

            <CardContent>
              <Typography
                component="h4"
                variant="title"
                style={{
                  textAlign: 'left',
                  color: '#EB557A'
                }}
              >
                {course.header}
              </Typography>
              <Stack direction="row" spacing={1} mt={1}>
                <AccessTimeIcon
                  sx={{ fontSize: 18 }}
                  style={{
                    color: '#EB557A'
                  }}
                />
                <Typography
                  variant="body2"
                  paragraph
                  style={{
                    color: '#EB557A'
                  }}
                >
                  {`${course.minutes} ${t('course.minute')} `}
                </Typography>
              </Stack>
            </CardContent>
          </ButtonBase>
        </Card>
      </Grid>
    )
  }

  return (
    <>
      <MainLayout title={t('course.title')} loading={loading}>
        {!loading ? (
          <Container maxWidth="sm" sx={{ p: 0 }}>
            <Box px={3}>
              {courses.length > 0 &&
                courses.map(
                  (course, index) =>
                    course?.subscribed && MyCourse(course, index)
                )}
            </Box>
          </Container>
        ) : (
          <LinearProgress />
        )}
      </MainLayout>
    </>
  )
}

export default MyCourses
