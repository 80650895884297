import React, { useState, useMemo } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { CardActionArea } from '@mui/material'
import ReactBnbGallery from 'react-bnb-gallery'

export default function Album({ album }) {
  const [isOpen, setIsOpen] = useState(false)

  const Images = useMemo(() => {
    return album?.images.map((album) => ({
      photo: album.url,
      caption: album.description
    }))
  }, [album.images])

  return (
    <Card sx={{ minWidth: 320, maxWidth: 345 }}>
      <CardActionArea onClick={() => setIsOpen(true)}>
        <CardMedia
          component="img"
          height="140"
          image={album?.images[0]?.url}
          alt={album?.title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {album?.title}
          </Typography>
          <Typography gutterBottom variant="p" component="div">
            {album?.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <ReactBnbGallery
        show={isOpen}
        photos={Images}
        onClose={() => setIsOpen(false)}
      />
    </Card>
  )
}
