/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
  Stack,
  CardActionArea,
  AppBar,
  Toolbar,
  InputBase
} from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { MainLayout } from 'layouts'
import { useAuth, useFeeds } from 'hooks'
import { useAppContext } from 'context'
import Carousel from 'react-material-ui-carousel'
import FeedCard from 'components/FeedCard'
import SearchIcon from '@mui/icons-material/Search'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

function ElevationScroll(props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 1
  })
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 1),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '40ch',
      '&:focus': {
        width: '50ch'
      }
    }
  }
}))

const Homepage = ({ promptToInstall }) => {
  let history = useHistory()
  const { t } = useTranslation('home')
  const { setBackdrop } = useAppContext()
  const [globalExpanded, setGlobalExpanded] = useState(false)
  const [query, setQuery] = useState('')

  const {
    loading,
    getFeeds,
    clickLikeFeed,
    commentFeed,
    posting,
    searchFeed,
    getBanners
  } = useFeeds()
  const [feeds, setFeeds] = useState([])
  const [banners, setBanner] = useState([])

  const { user } = useAuth()

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (query.length > 0 && query !== '') {
        handleSearch(query)
      } else {
        fetchData(user.uid, true)
      }
    }, 500)
    return () => clearTimeout(timeOutId)
  }, [query])

  const handleSearch = async (text) => {
    const feeds = await searchFeed(text)
    setFeeds(feeds)
  }

  const handleLike = async (feedId, likes) => {
    let result = await clickLikeFeed(feedId, likes)
    if (result) {
      fetchData(user.uid, true)
    }
  }

  const handleComment = async (feedId, comments, newComment) => {
    let result = await commentFeed(feedId, comments, newComment)
    if (result) {
      fetchData(user.uid, true)
    }
  }

  useEffect(() => {
    if (user?.uid) {
      fetchData(user.uid)
    }
  }, [user])

  const fetchData = async (uid, notRefresh) => {
    const result = await getFeeds(uid, notRefresh)
    const bannersRes = await getBanners()
    setFeeds(result)
    setBanner(bannersRes)
  }

  const MenuButton = () => (
    <Box mt={6} mb={5}>
      <Stack
        direction="row"
        spacing={4}
        justifyContent="center"
        alignItems="center"
      >
        <CardActionArea
          onClick={() => {
            history.push(`/history`)
            setBackdrop(true)
          }}
          sx={{
            width: 90,
            height: 90
          }}
        >
          <CardMedia
            component="img"
            sx={{
              width: 70,
              height: 70,
              position: 'absolute',
              left: 15,
              top: -45
            }}
            loading="lazy"
            image="/Icon_Manu-01.png"
            alt="Live from space album cover"
          />
          <CardContent
            style={{
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#A1D6D5',
              borderRadius: 10,
              width: 90,
              height: 90,
              padding: 4
            }}
          >
            <Typography variant="body1" color="#FFF" component="div" mt={1}>
              {t('buttons.lastLesson')}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActionArea
          onClick={() => {
            history.push(`/course`)
            setBackdrop(true)
          }}
          sx={{
            width: 90,
            height: 90
          }}
        >
          <CardMedia
            component="img"
            sx={{
              width: 70,
              height: 70,
              position: 'absolute',
              left: 10,
              top: -45
            }}
            loading="lazy"
            image="/Icon_Manu-02.png"
            alt="Live from space album cover"
          />
          <CardContent
            style={{
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#A1D6D5',
              borderRadius: 10,
              width: 90,
              height: 90,
              padding: 4
            }}
          >
            <Typography variant="body1" color="#FFF" component="div" mt={1}>
              {t('buttons.course')}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActionArea
          onClick={() =>
            window.open('https://www.facebook.com/cakemehomebakingstudio')
          }
          sx={{
            width: 90,
            height: 90
          }}
        >
          <CardMedia
            component="img"
            sx={{
              width: 70,
              height: 70,
              position: 'absolute',
              left: 10,
              top: -45
            }}
            loading="lazy"
            image="/Icon_Manu-03.png"
            alt="Live from space album cover"
          />
          <CardContent
            style={{
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#A1D6D5',
              borderRadius: 10,
              width: 90,
              height: 90,
              padding: 4
            }}
          >
            <Typography variant="body1" color="#FFF" component="div" mt={1}>
              {t('buttons.facebook')}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Stack>
    </Box>
  )

  const [didMount, setDidMount] = useState(false)

  useEffect(() => {
    setDidMount(true)
    return () => setDidMount(false)
  }, [])

  if (!didMount) {
    return null
  }

  return (
    <MainLayout
      title={t('title')}
      loading={loading}
      promptToInstall={promptToInstall}
    >
      <Box mt={2} sx={{ p: 0 }}>
        <Container maxWidth="sm" sx={{ p: 0 }}>
          <Box mt={3} height="200">
            <Carousel
              autoPlay
              // animation="slide"
              navButtonsAlwaysVisible
              NextIcon={<ChevronRightIcon color="primary" fontSize="50px" />}
              PrevIcon={<ChevronLeftIcon color="primary" fontSize="50px" />}
              navButtonsProps={{
                // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                style: {
                  backgroundColor: 'transparent'
                }
              }}
            >
              {banners &&
                banners?.map((banner, index) => (
                  <Grid
                    key={'slide-' + index}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Card
                      sx={{ maxWidth: 300 }}
                      style={{
                        backgroundColor: 'pink',
                        borderRadius: 10
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="200"
                        width="auto"
                        image={banner?.photoURL}
                        alt={'slide-' + index}
                        loading="lazy"
                      />
                    </Card>
                  </Grid>
                ))}
            </Carousel>
          </Box>

          <MenuButton />

          <Box
            mt={3}
            p={3}
            sx={{
              backgroundColor: (theme) => theme.palette.primary.main,
              borderRadius: { sm: 2 },
              minHeight: 800
            }}
          >
            <Typography component="h1" variant="title" color="#FFF">
              {t('feeds.title')}
            </Typography>
            <ElevationScroll>
              <AppBar position="relative" sx={{ borderRadius: 1 }}>
                <Toolbar>
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      inputProps={{ 'aria-label': 'search' }}
                      onChange={(e) => setQuery(e.target.value)}
                    />
                  </Search>
                </Toolbar>
              </AppBar>
            </ElevationScroll>
            <Box>
              {feeds?.map((feed, index) => (
                <FeedCard
                  key={`feeds-key-${index}`}
                  loading={posting}
                  data={feed}
                  index={index}
                  expanded={globalExpanded === index}
                  setGlobalExpanded={setGlobalExpanded}
                  clickLikeFeed={() => handleLike(feed.id, feed.likes)}
                  handleComment={(newComment) =>
                    handleComment(feed.id, feed.comments, newComment)
                  }
                />
              ))}
            </Box>
          </Box>
        </Container>
      </Box>
    </MainLayout>
  )
}

export default Homepage
