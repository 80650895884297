import { Button as MaterialButton } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Button = styled(MaterialButton)(({ theme }) => ({
  color: 'white',
  backgroundColor: theme.palette.secondary[500],
  '&:hover': {
    backgroundColor: theme.palette.secondary[700]
  },
  borderRadius: 20
}))

export default Button
