import React, { useRef, useState } from 'react'

import { Box, IconButton } from '@mui/material'
import Compressor from 'compressorjs'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import Avatar from 'components/Avatar'
import { useImage } from 'hooks'

const PREVIEW_SIZE = 130
const UPLOAD_SIZE = 130

export const UploadAvatarImage = (props) => {
  const { filename, imageUrl, callback } = props
  const { upload } = useImage()
  const inputFile = useRef(null)
  const [preview, setPreview] = useState(null)
  const [uploadFile, setUploadFile] = useState(null)

  const handleChange = (e) => {
    const { target } = e

    const files = e.target.files

    if (target.validity.valid) {
      // Resize Preview Image
      new Compressor(files[0], {
        quality: 1,
        width: PREVIEW_SIZE,
        height: PREVIEW_SIZE,
        success(result) {
          setPreview(result)
        }
      })

      // Resize Upload Image
      new Compressor(files[0], {
        quality: 1,
        width: UPLOAD_SIZE,
        height: UPLOAD_SIZE,
        success(result) {
          setUploadFile(result)
        }
      })
    }
  }

  const handleUpload = () => {
    upload(uploadFile, filename, 'avartar', (photoURL) => {
      callback(photoURL)

      setPreview(null)
      setUploadFile(null)
    })
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <input
        id="button-file"
        ref={inputFile}
        type="file"
        accept="image/*"
        onChange={handleChange}
        style={{ display: 'none' }}
      />

      <IconButton
        onClick={() => {
          if (inputFile.current !== null) {
            inputFile?.current.click()
          }
        }}
      >
        {preview ? (
          <Avatar src={URL.createObjectURL(preview)} size="large" />
        ) : (
          <>
            <Avatar src={imageUrl || undefined} size="large" />
            <PhotoCameraIcon
              color="grey"
              style={{ position: 'absolute', zIndex: 1, bottom: 5, right: 5 }}
            />
          </>
        )}
      </IconButton>

      {preview && (
        <Box>
          <IconButton
            size="small"
            onClick={() => {
              setPreview(null)
              setUploadFile(null)
            }}
            color="secondary"
          >
            <ClearIcon />
          </IconButton>

          <IconButton size="small" onClick={handleUpload} color="primary">
            <CheckIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

export default UploadAvatarImage
