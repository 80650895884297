/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line react-hooks/exhaustive-deps
import { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Grid,
  Stack,
  Avatar,
  IconButton,
  Typography,
  Paper,
  InputBase,
  Divider,
  Collapse,
  Box,
  MenuItem,
  Menu,
  Dialog,
  DialogTitle,
  DialogActions,
  Button
} from '@mui/material'
import dayjs from 'dayjs'
import LoadingButton from '@mui/lab/LoadingButton'

import FavoriteIcon from '@mui/icons-material/Favorite'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ModeCommentIcon from '@mui/icons-material/ModeComment'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import { useAppContext } from 'context'

function truncateString(str, num) {
  if (str && str.length > num) {
    return str.slice(0, num) + '...'
  } else {
    return str
  }
}

const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}))

const FeedCard = ({
  data,
  index,
  expanded,
  setGlobalExpanded,
  clickLikeFeed,
  loading,
  handleComment,
  deleteBtn,
  deleteHomeWork,
  handleEdit
}) => {
  const { user } = useAppContext()
  const [comment, setComment] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [dialogOpen, setOpenDialog] = useState(false)
  const [isLiked, setLike] = useState(false)
  const [likes, setLikes] = useState(null)

  useEffect(() => {
    setLikes(data?.likes.length)
    setLike(data?.likes?.indexOf(user.uid) !== -1)
  }, [data?.likes])

  const handleExpandClick = () => {
    setGlobalExpanded(!expanded ? index : false)
  }

  const handleCommentSubmit = () => {
    if (comment !== '') {
      setComment('')
      handleComment(comment)
    }
  }

  const handleChange = (event) => {
    setComment(event.target.value)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickOpen = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleDeleteHomework = () => {
    handleClose()
    deleteHomeWork()
    setOpenDialog(false)
  }

  const handleClickEdit = () => {
    handleEdit()
    handleClose()
  }

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete this homework ?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleDeleteHomework} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Collapse in={!expanded} timeout="auto" unmountOnExit>
        <Grid sx={{ my: 1 }} item key={`feeds-key-${index}`}>
          <Card
            sx={{
              display: 'flex',
              height: 170
            }}
            elevation={10}
          >
            <div>
              <CardMedia
                component="img"
                sx={{ width: 150, height: '100%', objectFit: 'cover' }}
                image={data?.photoURL}
                alt={'homework'}
              />
            </div>
            <Box
              sx={{
                width: '100%'
              }}
            >
              <div style={{ height: '125px' }}>
                <CardHeader
                  sx={{ py: 0.7 }}
                  title={
                    <>
                      <Typography component="h6">{`${
                        data?.user?.firstname || ''
                      } ${data?.user?.lastname || ''}`}</Typography>
                      <Stack direction="column">
                        {data?.type === 'homework' ? (
                          <Typography
                            component="h5"
                            color="#F996FF"
                          >{` การบ้าน`}</Typography>
                        ) : (
                          <Typography
                            component="h5"
                            color="#8AD8FF"
                          >{`บทความ`}</Typography>
                        )}
                        <Typography component="h5">{`${data?.title} `}</Typography>
                      </Stack>
                    </>
                  }
                  subheader={
                    <Typography variant="caption" color="text.secondary">
                      {dayjs(data?.createdAt.toDate()).format(
                        'MMM DD YYYY HH:mm'
                      )}
                    </Typography>
                  }
                  action={
                    deleteBtn && (
                      <div>
                        <IconButton
                          aria-label="setting"
                          aria-expanded={open ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={handleClick}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          PaperProps={{
                            style: {
                              maxHeight: 48 * 4.5
                            }
                          }}
                        >
                          <MenuItem onClick={handleClickEdit}>Edit</MenuItem>
                          <MenuItem onClick={handleClickOpen}>Delete</MenuItem>
                        </Menu>
                      </div>
                    )
                  }
                />
                <CardContent
                  sx={{
                    flex: 1,
                    textAlign: 'start',
                    py: 0
                  }}
                >
                  {/* <Typography variant="body2" color="text.secondary">
                    {truncateString(data?.detail, 50)}
                  </Typography> */}
                </CardContent>
              </div>

              <CardActions disableSpacing sx={{ position: 'relative' }}>
                <Stack
                  direction="row"
                  spacing={0.5}
                  justifyContent="start"
                  alignItems="center"
                >
                  <FavoriteIcon
                    sx={{ fontSize: 15 }}
                    style={{
                      color: '#EB557A'
                    }}
                  />
                  <Typography
                    variant="body2"
                    style={{
                      color: '#EB557A'
                    }}
                  >
                    {data?.likes?.length}
                  </Typography>

                  <ModeCommentIcon
                    sx={{ fontSize: 15 }}
                    style={{
                      color: '#EB557A'
                    }}
                  />
                  <Typography
                    variant="body2"
                    style={{
                      color: '#EB557A'
                    }}
                  >
                    {data?.comments?.length}
                  </Typography>
                </Stack>

                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </CardActions>
            </Box>
          </Card>
        </Grid>
      </Collapse>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Card sx={{ my: 1 }} elevation={10}>
          <CardHeader
            avatar={
              <Avatar aria-label="homework" src={data?.user?.photoURL}></Avatar>
            }
            title={
              <>
                <Typography component="h6">{`${data?.user?.firstname || ''} ${
                  data?.user?.lastname || ''
                }`}</Typography>
                <Stack direction="column">
                  {data?.type === 'homework' ? (
                    <Typography
                      component="h5"
                      color="#F996FF"
                    >{` การบ้าน`}</Typography>
                  ) : (
                    <Typography
                      component="h5"
                      color="#8AD8FF"
                    >{` บทความ`}</Typography>
                  )}
                  <Typography component="h5">{`${data?.title}`}</Typography>
                </Stack>
              </>
            }
            subheader={dayjs(data?.createdAt.toDate()).format(
              'MMM DD YYYY HH:mm'
            )}
            action={
              <ExpandMore
                expand={expanded}
                onClick={() => handleExpandClick()}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            }
          />
          <CardMedia
            component="img"
            image={data?.photoURL}
            alt={data?.photoURL}
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {truncateString(data?.detail, 500)}
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <Stack
              direction="row"
              spacing={0.5}
              justifyContent="start"
              alignItems="center"
            >
              <IconButton
                aria-label="love"
                onClick={() => {
                  setLike(!isLiked)
                  setLikes(isLiked ? likes - 1 : likes + 1)
                  clickLikeFeed(data?.likes + 1)
                }}
              >
                <FavoriteIcon
                  style={{
                    color: isLiked ? '#EB557A' : undefined
                  }}
                />
              </IconButton>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{
                  color: isLiked ? '#EB557A' : undefined
                }}
              >
                {likes}
              </Typography>

              <IconButton aria-label="comment">
                <ModeCommentIcon />
              </IconButton>
              <Typography variant="body2" color="text.secondary">
                {data?.comments?.length}
              </Typography>
            </Stack>
          </CardActions>
          {data?.comments?.map((comment, index) => (
            <div key={`comment-index-${index}`}>
              <CardHeader
                avatar={<Avatar src={comment?.user?.photoURL} size="large" />}
                title={
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <Typography component="h6">{`${
                      comment?.user?.firstname || ''
                    } ${comment?.user?.lastname || ''} `}</Typography>
                    <Typography color="secondary">
                      {comment?.user?.isAdmin ? '(Admin)' : ''}
                    </Typography>
                  </Stack>
                }
                subheader={truncateString(comment?.text, 50)}
              />
            </div>
          ))}
          <Divider />
          <Paper
            component="form"
            sx={{ p: 1, display: 'flex', alignItems: 'center' }}
          >
            <Avatar src={user?.profile?.photoURL || null} size="small" />

            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="เพิ่มความคิดเห็น..."
              inputProps={{ 'aria-label': 'เพิ่มความคิดเห็น...' }}
              onChange={handleChange}
              value={comment}
            />
            <LoadingButton
              loading={loading}
              sx={{ p: '10px' }}
              loadingIndicator={'Posting...'}
              onClick={() => handleCommentSubmit()}
            >
              Post
            </LoadingButton>
          </Paper>
        </Card>
      </Collapse>
    </>
  )
}

export default FeedCard
